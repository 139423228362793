import React, { useState, useEffect } from "react";
import { Button, Input, Space, notification, Form } from "antd";
import _service from "@netuno/service-client";
import "./index.less";
import Cluar from "../../../common/Cluar";

export function Newsletter() {
    const [newsletterForm] = Form.useForm(null);
    const [loading, setLoading] = useState(false);

    const validateMessages = {
        required: Cluar.dictionary("form-validate-required"),
        types: {
            email: Cluar.dictionary("form-validate-email"),
        },
    };

    const handleFinish = (values) => {
        const fail = (e) => {
            setLoading(false);
            notification["error"]({
                description: Cluar.plainDictionary("contact-notification-fail"),
            });
        };
        _service({
            method: "POST",
            url: "newsletter",
            data: values,
            success: (response) => {
                if (response.json.result === true) {
                    setLoading(false);
                    notification["success"]({
                        message: Cluar.plainDictionary("contact-notification-title"),
                        description: Cluar.plainDictionary("contact-notification-success"),
                    });
                    newsletterForm.resetFields();
                } else {
                    fail()
                }
            },
            fail: (e) => {
                if (e && e.response && e.response.status == 413) {
                    setLoading(false);
                    notification.warning({
                        message: Cluar.plainDictionary("contact-notification-title"),
                        description: Cluar.plainDictionary(
                            "contact-notification-warning-file-size"
                        ),
                        top: 100,
                    });
                } else {
                    console.log("ContactForm failed:", e);
                    fail()
                }
            },
        });
    };

    return (
        <Form
            validateMessages={validateMessages}
            form={newsletterForm}
            onFinish={handleFinish}
            className="form-newsletter"
        >
            <Form.Item
                name="email"
                rules={[{ required: true, type: "email" }]}
            >
                <Input size="large" placeholder={Cluar.plainDictionary("placeholder-email")} />
            </Form.Item>
            <Button htmlType="submit">
                {Cluar.plainDictionary("get-started-now")}
            </Button>
        </Form>
    )
}