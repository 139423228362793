import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Dictionary from '../../../Dictionary';
import Cluar from '../../../../common/Cluar';
import './index.less';
import { useState } from 'react';

const cardData = [
    {
        index: 0,
        title: 'Start',
        money: '7€',
        moneyTitle: {
            en: '/month',
            pt: '/mês',
        },
        cpuPlan: '1 Core - CPU',
        ramPlan: '3 GB - RAM',
        ssdPlan: '25 GB - SSD',
    },
    {
        index: 1,
        title: 'Pro',
        money: '14€',
        moneyTitle: {
            en: '/month',
            pt: '/mês',
        },
        cpuPlan: '2 Cores - CPU',
        ramPlan: '6 GB - RAM',
        ssdPlan: '50 GB - SSD',
    },
];
export function CardsDevelop() {

    const languages = Cluar.currentLanguage().locale === 'pt' ? 'pt' : 'en'
    const [focusCardIndex, setFocusCardIndex] = useState(1);

    return (
        <div className='cards-container'>
            <div className='heading text_center'>
                <Dictionary entry="session-title-develop" oneLine />
            </div>
            <div className="switch_plans_container_wrapper">
                {cardData.map((card, index) => (
                    <div key={index} className={`${index === focusCardIndex ? "switch_plans_container_wrapper_card--focus" : "switch_plans_container_wrapper_card"
                        }`}>
                        <div className={`${index === focusCardIndex ? "switch_plans_container_wrapper_card--focus_heading" : "switch_plans_container_wrapper_card_heading"
                            }`}>
                            <h1 className="title">{card.title}</h1>
                            <p className='title-money'>{card.money}
                                <span className='complementary-money' >
                                    {card.moneyTitle[languages]}
                                </span>
                            </p>
                        </div>
                        <hr />
                        <ul>
                            <li>{card.cpuPlan}</li>
                            <li>{card.ramPlan}</li>
                            <li>{card.ssdPlan}</li>
                        </ul>
                    </div>
                ))}
            </div>
            <div className='more-info'>
                <a href={`/${Cluar.currentLanguage().locale}/vps/${Cluar.currentLanguage().locale === 'pt' ? 'desenvolvedor' : 'developer'}`}>
                    <span className='more-info-button'>
                        Saiba mais
                        <ArrowRightOutlined style={{
                            fontSize: "16px"
                        }} />
                    </span>
                </a>
            </div>
        </div>
    );
}
