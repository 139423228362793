import React from 'react';
import { Col, Row, Image } from 'antd';

import "./index.less";

const ProductsPageTemplate = ({...props}) => {
	return(
		<div>
			<section className='banner-hero'>
				<Row align="middle" className='banner-hero__row'>
					<Col span={12} className='banner-hero__row__info'>
						<span>Some short text</span>
						<h2>Desenvolvimento</h2>
						<p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
					</Col>
					<Col span={12} className="banner-hero__row__img" style={{background: "url(https://blog.hyperiondev.com/wp-content/uploads/2019/02/Blog-Types-of-Web-Dev.jpg)", }}></Col>
				</Row>
			</section>
			<section className='short-plans'>
				<Row gutter={0} className="short-plans__row">
					<Col span={7} className='short-plans__row__item'>
						<div>
							<span>Plano Dev100</span>
							<p>Uma linha ou duas de blabla sobre o plano com conteúdo para duas linhas</p>
							<span>€--,-/Mês</span>
						</div>
					</Col>
					<Col span={7} className='short-plans__row__item'>
						<div>
							<span>Plano Dev100</span>
							<p>Uma linha ou duas de blabla sobre o plano com conteúdo para duas linhas</p>
							<span>€--,-/Mês</span>
						</div>
					</Col>
					<Col span={7} className='short-plans__row__item'>
						<div>
							<span>Plano Dev100</span>
							<p>Uma linha ou duas de blabla sobre o plano com conteúdo para duas linhas</p>
							<span>€--,-/Mês</span>
						</div>
					</Col>
				</Row>
			</section>
		</div>	
	)
}

export default ProductsPageTemplate
	