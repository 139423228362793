import React, { useState } from "react";
import { WhatsAppOutlined } from '@ant-design/icons';
import ReactCountryFlag from "react-country-flag"
import { Modal } from 'antd';
import './index.less'

import Dictionary from '../../Dictionary';

export default () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="styles_container">
      <div className="contact-whatsapp-fix">
        <a title="WhatsApp" onClick={showModal}>
          <WhatsAppOutlined style={{ fontSize: '32px', color: '#fff' }} />
        </a>
        <Modal
          title={<Dictionary entry="title-modal-flag" oneLine />}
          open={open}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="flags">
            <a href='https://wa.me/+351912524751' target="_blank">
              <ReactCountryFlag
                countryCode="PT"
                svg
                style={{
                  width: '4.5em',
                  height: '4.5em',
                }}
                title="PT"
              />
            </a>
            <a href='https://wa.me/+5511968007070' target="_blank"> 
              <ReactCountryFlag
                countryCode="br"
                svg
                style={{
                  width: '4.5em',
                  height: '4.5em',
                }}
                title="br"
              />
            </a>
          </div>
        </Modal>
      </div>
    </div>
  );
};
