import React, { useState } from 'react';
import Dictionary from '../../Dictionary';
import { Space, Switch } from 'antd';

import './index.less';
import { CardsContainer } from './CardsContainer';
import { CardsDevelop } from './CardsDevelop';

export default ({ title, content }) => {


    const [switchValue, setSwitchValue] = useState(0);

    const handleSwitchChange = (value) => {
        setSwitchValue(value ? 1 : 0);
    }
    return (
        <section className={`card-plans`}>
            <div className={`card-plans__wrapper styles_container`}>
                <CardsDevelop />
                <CardsContainer />
            </div>
        </section>
    );

}