import React, { useState } from "react";

import {
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Col,
  Row,
  Switch,
  DatePicker,
  TimePicker
} from "antd";

import { UploadOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";

import "./index.less";


const onFinish = (values) => {
  console.log(values);
}


const format = 'HH:mm';

export default ({ title, content }) => {

  return (
    <section className="website-form container">
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
      >
        <h1 className="title center-content">{title}</h1>
        <h6 className="sub-title center-content">{content}</h6>
        <Row justify="space-between" gutter={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name={['name']}
              label="Nome"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Nome" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name={['Nome da Empresa']}
              label="Nome da Empresa"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Nome da Empresa"/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name={['email']}
              label="Email"
              rules={[{ type: 'email', required: true },
              ]}
            >
              <Input  placeholder="Email"/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name={['telefone']}
              label="Telefone (WhatsApp)"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Telefone" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Categoria"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select>
                <Select.Option value="demo">Demo</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Setor"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select>
                <Select.Option value="demo">Demo</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name={['disponibilidade']}
              label="Disponibilidade"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Group compact>
                <Form.Item
                  name={['disponibilidade', 'data']}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Data is required',
                    },
                  ]}
                >
                  <DatePicker placeholder="Data"/>
                </Form.Item>
                <Form.Item
                  name={['address', 'hora']}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Hora is required',
                    },
                  ]}
                >
                  <TimePicker
                    format={format} placeholder="Hora" />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name={'contact_available'}
              label="Tem site Atual?"
              layout="vertical"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item name={['informação']} label="Informação Adicional">
              <Input.TextArea rows={8}  placeholder="Informação Adicional"/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          wrapperCol={{
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};
