import React from 'react';
import Dictionary from '../../Dictionary';

import './index.less';

export default ({ title, content }) => {
    return (
        <section className={`container-plans`}>
            <div className={`container-plans__wrapper styles_container`}>
                <div className={`heading`}>
                    <h1 className="title-money fw-700">{title}</h1>
                </div>
                <div className={`container-plans__body`}>
                    <div>
                        <div class="plans">
                            <div class="c pricing-containe">
                                <div class="title-plan">
                                    <h2>C</h2>
                                    <h4>20 €<Dictionary entry="monthly-payment" className="monthly-payment"/></h4>
                                </div>
                                <ul>
                                    <li>✓ 2 cores - CPU</li>
                                    <li>✓ 6 GB - RAM</li>
                                    <li>✓ 200 GB - HDD or 50 GB - SSD</li>
                                    <li>✓ 15€ Initial Setup Fee</li>
                                </ul>
                            </div>
                            <div class="cs pricing-containe">
                                <div class="title-plan">
                                    <h2>CS</h2>
                                    <h4>40 €<Dictionary entry="monthly-payment" className="monthly-payment"/></h4>
                                </div>
                                <ul>
                                    <li>✓ 4 cores - CPU</li>
                                    <li>✓ 14 GB - RAM</li>
                                    <li>✓ 400 GB - HDD or 100 GB - SSD</li>
                                    <li>✓ 15€ Initial Setup Fee</li>
                                </ul>
                            </div>
                            <div class="cl pricing-containe">
                                <div class="title-plan">
                                    <h2>CL</h2>
                                    <h4>80 €<Dictionary entry="monthly-payment"/></h4>
                                </div>
                                <ul>
                                    <li>✓ 8 cores - CPU</li>
                                    <li>✓ 30 GB - RAM</li>
                                    <li>✓ 800 GB - HDD or 200 GB - SSD</li>
                                    <li>✓ 15€ Initial Setup Fee</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}