import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import Item from './Item';
import Dictionary from '../Dictionary';

import './index.less';
import Cluar from '../../common/Cluar';

export default ({ section, type, image, image_title, image_alt, title, content, items, index, name }) => {
  const [effect, setEffect] = useState(false)
  const [value, setvalue] = useState()

  window.addEventListener('scroll', function() {
    var section = document.getElementById('section-vps-services');
    if (section) {
      var rect = section.getBoundingClientRect();
      var offset = 500;

      if (rect.top - offset >= 0) {
        setEffect(false);
        console.log("acima");
        setvalue(0);
      } else {
        setEffect(true);
        setvalue(900);
        console.log("abaixo!");
        setvalue(900);
      }
    }
  });

  const children = items.map((item, i) => (
    <Item
      key={item.uid + i}
      index={i}
      total={items.length}
      type={type}
      {...item}
      effect={effect}
      value={value}
    />
  ));

  let listLayout = (
      <div className="styles_container">
        <div className={`listing_${type}`}>
          {children}
        </div>
    </div>
  );

  if (type === 'listing_tablePlans') {
    listLayout = (
      <Row className={`listing__${type}`} justify="start">
      </Row>
    );
  } else if (type === 'table_services') {
    listLayout = (
      <div className="styles_container styles_container__rXh9g">
        <div className="styles_section styles_tableRows">
          <table className={`listing__${type}`}>
            {children}
          </table>
        </div>
      </div>
    );
  } else if (type === 'list_of_technologies') {
    listLayout = (
      <div className='list_of_technologies'>
        <div className="styles_container has-color">
          <div className='heading'>
            <h1 className='heading-title fw-700'>{title}</h1>
          </div>
          <div className={`listing__${type}`}>
            <Row gutter={[20, 20]} justify='center' wrap="true">
              {children}
            </Row>
          </div>
          <div className='content' dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>

    );
  } else if (type === 'pricing_plans') {
    listLayout = (
      <div className="pricing">
        <div className="styles_section styles_container styles_container__rXh9gs">
          <div className="text_center">
            <h1 className="mt-0 fw-700">{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
          <div className={`listing_${type}`} >
            <div className='illustration-element-06'/>
            {children}
          </div>
        </div>
      </div>
    );
  } else if (type === 'contracting_services') {
    listLayout = (
      <div className="styles_section styles_container styles_container__rXh9gs">
        <div className="text_center" id='section-vps-services'>
          <h1 className="mt-0 fw-700">{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
        <div className={`listing_${type}`} >
          {children}
        </div>
      </div>
    );
  } else if (type === 'vps_services') {
    listLayout = (
      <div className="styles_section styles_container styles_container__rXh9gs">
        <div className="text_center" id='section-vps-services'>
          <h1 className="mt-0 fw-700">{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
        <div className={`listing_${type}`} >
          {children}
        </div>
      </div>
    );
  } else if (type === 'section_codespace') {
    listLayout = (
      <div className="styles_container">
        {/*<div className="listing__title-border"></div>*/}
        <div className={`listing_${type}`}>
          {children}
        </div>
      </div>

    );
  }

  else if (type === 'section-dev') {
    listLayout = (
      <div className='short-plans styles_container '>
        <div className="text_center">
          <h1 className="mt-0 fw-700">{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
        <Row className="short-plans_row" wrap={true} justify='center'>
          <div className='short-plans-wrapper illustration-section-03'>{children}</div>
        </Row>
      </div>
    );
  }

  else if (type === 'short-pricing') {
    listLayout = (
      <section className='ProductsShortPricing styles_container'>
        <div className='ProductsShortPricing_heading'>
          <h1 className='productsShortPricing_heading_title'>{title}</h1>
        </div>
        <Row gutter={0} className="ProductsShortPricing_row" wrap={true} justify='center'>
          <div className='productsShortPricing_wrapper'>{children}</div>
        </Row>
      </section>
    );
  }
  else if (type === 'short-pricing-container') {
    listLayout = (
      <section className='ProductsShortPricing styles_container'>
        <div className='ProductsShortPricing_heading'>
          <h1 className='productsShortPricing_heading_title'>{title}</h1>
        </div>
        <Row gutter={0} className="ProductsShortPricing_row" wrap={true} justify='center'>
          <div className='productsShortPricing_wrapper'>{children}</div>
        </Row>
      </section>
    );
  }

  return (
    <>
      <section className={`listing ${type}`}>
        {listLayout}
      </section>
    </>
  );
}
