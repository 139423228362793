import React from 'react';
import { Row, Col, Button, Image } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import Actions from '../Actions';
import Cluar from '../../common/Cluar';
import Dictionary from '../../components/Dictionary';

import Banner1 from '../../assets/images/freelance-copywriter-02-homepage-600x800.jpg';
import Banner2 from '../../assets/images/cosmetic-store-homepage-600x800.jpg';
import Banner3 from '../../assets/images/restaurant-04-home-1-600x800.jpg';


import './index.less';

export default ({ section, type, title, content, image, image_title, image_alt, image_max_width, actions, page }) => {
  let layout = null;
  const imageStyle = {};
  if (image_max_width > 0) {
    imageStyle["maxWidth"] = `${image_max_width}px`;
  }

  if (type === 'text') {
    layout = (
      <div className="content__text">
        <div className="text">
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    );

  }
  else if (type === 'plan-text') {
    layout = (
      <div className="plan_text">
        <div className="text">
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
          <div className='button-lg'>
            <Button className="ml-4" >
              Comprar
            </Button>
          </div>

        </div>
      </div>
    );

  } else if (type === 'textoParagrafo') {
    layout = (
      <div className="content__textoParagrafo styles_section styles_container ">
        <div className="textoParagrafo ">
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    );

  } else if (type === 'image-left') {
    layout = (
      <div className="content__image-left">
        <Row>
          <Col md={12}>
            <div className="image">
              <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} style={imageStyle} />
            </div>
          </Col>
          <Col md={8}>
            <div className="text">
              <span className="text-xxs">Saiba mais, saiba tudo</span>
              <h1>{title}</h1>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </Col>
        </Row>
      </div>
    );
  } else if (type === 'image-right') {
    layout = (
      <div className="content__image-right">
        <div className="container">
          <div className="split-item-content">
            <span className="text-xxs"><Dictionary entry="texto-wordpress" oneLine /></span>
            <h1 className="mb-12">{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
          <div className="split-item-image" >
            <div className="image">
              <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === 'image-top') {
    layout = (
      <div className="content__image-top styles_container">
        <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} style={imageStyle} />
      </div >
    );
  } else if (type === 'image-bottom') {
    layout = (
      <div className="styles_section styles_container">
        <div className="content__image-bottom ">
          <div className="tiles-wrap-title styles_content">
            <div className="mt-0 mb-16">
              <h1 data-sal="slide-up" data-sal-duration="1200" data-sal-delay="500" data-sal-easing="ease-out-back" className='fw-700'>{title}</h1>
              <div data-sal="slide-up" data-sal-duration="1200" data-sal-delay="500" data-sal-easing="ease-out-back" className="m-0" dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </div>
          <div className="tiles-wrap illustration-section-03">
            <div className="tiles-item center-content reveal-scale-up">
              <div className="tiles-item-inner has-shadow">
                <div className="layouts-item-content">
                  <img
                    data-sal="fade" data-sal-duration="2000" data-sal-easing="ease-out-back"
                    src={Banner3}
                    alt="Layout 01"
                    width={282}
                    height={371} />
                </div>
                <div className="layouts-item-footer pt-32">
                  <span className="layouts-item-name fw-700"><Dictionary entry="restaurante" oneLine /></span>
                </div>
              </div>
            </div>
            <div className="tiles-item center-content reveal-scale-up">
              <div className="tiles-item-inner has-shadow">
                <div className="layouts-item-content">
                  <img
                    data-sal="fade" data-sal-duration="2000" data-sal-easing="ease-out-back"
                    src={Banner1}
                    alt="Layout 02"
                    width={282}
                    height={371} />
                </div>
                <div className="layouts-item-footer pt-32">
                  <span className="layouts-item-name fw-700"><Dictionary entry="freelancer" oneLine /></span>
                </div>
              </div>
            </div>
            <div className="tiles-item center-content reveal-scale-up">
              <div className="tiles-item-inner has-shadow">
                <div className="layouts-item-content">
                  <img
                    data-sal="fade" data-sal-duration="2000" data-sal-easing="ease-out-back"
                    src={Banner2}
                    alt="Layout 03"
                    width={282}
                    height={371} />
                </div>
                <div className="layouts-item-footer pt-32">
                  <span className="layouts-item-name text-sm fw-700"><Dictionary entry="loja-de-cosmetica" oneLine /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === 'image') {
    layout = (
      <div className="content__image">
        <div className="image">
          <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} style={imageStyle} />
        </div>
      </div>
    );
  } else if (type === 'development-container') {
    layout = (
      <div className={`content ${type}__box styles_container`}>
        <div className={`content ${type}__box-content`}>
          <div className={`${type}_text`}>
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
            {Cluar.currentLanguage().locale == 'pt' ?
              <Button>
                <a href={`/${Cluar.currentLanguage().locale}/vps/containers`}><Dictionary entry="learn-more" oneLine /></a>
              </Button>
              :
              <Button>
                <a href={`/${Cluar.currentLanguage().locale}/vps/containers`}><Dictionary entry="learn-more" oneLine /></a>
              </Button>
            }
          </div>
          <div className={`${type}_image`}>
            <img src="/images/containers-01.png" />
          </div>
        </div>
      </div>
    );
  } else if (type === 'footer-addicional') {
    layout = (
      <section className="cta-section has-bg-color container">
        <div className="cta-inner section-inner has-top-divider has-bottom-divider cta-split">
          <div className="cta-slogan">
            <h3 className="m-0 fw-700"><Dictionary entry="button-trabalhe-connosco" oneLine /></h3>
          </div>
          <div className="cta-action">
            <Button className="ml-4" >
              <Dictionary entry="button-criar-site-footer" oneLine />
            </Button>
          </div>
        </div>
      </section>
    );
  } else if (type === 'bannerContactUs') {
    layout = (
      <section className="section-us has-bg-color">
        <div className="styles_container section-us__wrapper">
          <div className="section-us__text">
            <h1 className="section-us__title m-0 fw-700">{title}</h1>
          </div>
          <div className="section-us__body-text">
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
            <Button onClick={(e) => {
              const currentLocale = Cluar.currentLanguage().locale;
              const newPath = `/${currentLocale}/${currentLocale === 'pt' ? 'fale-connosco' : 'contact-us'}`;
              window.location.href = newPath;
            }}>
              <Dictionary entry="buttonUS" oneLine />
            </Button>
          </div>
        </div>
      </section>
    );
  } else if (type === 'single-pricing') {
    layout = (
      <div className={`content__${type}`}>
        <div className={`content__${type}-wrapper styles_container`}>
          <div className={`content__${type}-heading`}>
            <h1 className="title-money fw-700">{title}</h1>
          </div>
          <div className={`content__${type}-body`}>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </div>
      </div>
    );
  } else {
    layout = (
      <div className={`content__${type}`}>
        <div className="image">
          <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} style={imageStyle} />
        </div>
        <div className="text">
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    );
  }
  return (
    <section className={`content ${type}`}>
      {layout}
      <Actions {... { section, type, actions }} />
    </section>
  );
}
