import React from 'react';

import './index.less';

export default ({ section, type, title, content, indication, link }) => {
  if (type === 'home-highlight') {
    return (
      <li>
        <div className="actions__item" onClick={ () => window.location = link}>
          {/*<button>{ indication !== '' ? <a href={link}>{indication}</a> : null}</button>*/}
        </div>
      </li>
    );
  }
  return (
      <li>
        <div className="actions__item" onClick={ () => window.location = link}>
          { title !== '' ? <h4>{title}</h4> : null}
          { content.replace(/ /g,'') !== '' ? <p dangerouslySetInnerHTML={{__html: content}} /> : null }
          { indication !== '' ? <a href={link}>{indication}</a> : null}
        </div>
      </li>
    );
}
