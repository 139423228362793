import React from 'react';

import './index.less';

export default () => {
    return (
        <main className="page--notfound">
            <h1>404</h1>
            <h2>Página não encontrada</h2>
        </main>
    );
};
