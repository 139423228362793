import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
  Select,
  Alert,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import _service from "@netuno/service-client";

import Cluar from "../../../common/Cluar";
import Dictionary from "../../../components/Dictionary";
import ReCAPTCHA from "react-google-recaptcha";

import "./index.less";

const { TextArea } = Input;
const { Option } = Select;

const layout = {
  rowGutter: { gutter: [25, 0] },
  labelCol: { span: "hide" },
  layout: "vertical",
};

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const formItemLayoutRequest = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default ({ title }) => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [isHuman, setIsHuman] = useState(false);
  const [contactForm] = Form.useForm(null);
  const recaptchaRef = React.createRef();

  const validateMessages = {
    required: Cluar.dictionary("form-validate-required"),
    types: {
      email: Cluar.dictionary("form-validate-email"),
    },
  };

  const propsUpload = {
    listType: "picture",
    maxCount: 1,
    beforeUpload: (file) => {
      const isLt50M = file.size / 1024 / 1024 <= 50;
      if (!isLt50M) {
        notification["warning"]({
          message: Cluar.plainDictionary(
            "contact-notification-large-file-title"
          ),
          description: Cluar.plainDictionary(
            "contact-notification-large-file-description"
          ),
        });
      }

      return isLt50M || Upload.LIST_IGNORE;
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleFinish = (values) => {
    const formData = new FormData();
    for (const key in values.contactForm) {
      const value = values.contactForm[key];
      if (key == "file") {
        if (value && value.length == 1) {
          formData.append(key, value[0].originFileObj);
        }
      } else {
        formData.append(key, values.contactForm[key]);
      }
    }
    values.contactForm.locale = window.localStorage.getItem("locale");
    setLoading(true);
    const fail = (e) => {
      setLoading(false);
      notification["error"]({
        message: Cluar.plainDictionary("contact-notification-title"),
        description: Cluar.plainDictionary("contact-notification-fail"),
      });
    };
    _service({
      method: "POST",
      url: "contact",
      data: formData,
      success: (response) => {
        if (response.json.result === true) {
          setLoading(false);
          setSent(true);
          console.log(formData)
          contactForm.resetFields()
          if (window.grecaptcha) {
            window.grecaptcha.reset();
          }
          notification["success"]({
            message: Cluar.plainDictionary("contact-notification-title"),
            description: Cluar.plainDictionary("contact-notification-success"),
          });
        } else {
          fail();
        }
      },
      fail: (e) => {
        if (e && e.response && e.response.status == 413) {
          setLoading(false);
          notification.warning({
            message: Cluar.plainDictionary("contact-notification-title"),
            description: Cluar.plainDictionary(
              "contact-notification-warning-file-size"
            ),
            top: 100,
          });
        } else {
          console.log("ContactForm failed:", e);
          fail();
        }
      },
    });
  };

  return (
    <section className="contact-form illustration-section-02 container">
      <Form
        form={contactForm}
        {...layout}
        validateMessages={validateMessages}
        onFinish={handleFinish}
      >
        <div className="center-content">
          <h2 className="title">{title}</h2>
        </div>
        <Row gutter={24} justify="space-around">
          <Col xs={24} sm={24} md={18} lg={16} xl={10}>
            <h3 className="left-content">
              <Dictionary entry="content-form" oneLine />
            </h3>
            <Form.Item
              {...layout.labelCol}
              label={Cluar.plainDictionary("label-name")}
              name={["contactForm", "name"]}
              rules={[
                {
                  required: true,
                },
                {
                  type: "string",
                  message: Cluar.plainDictionary("rules-error-name"),
                  pattern: "^(?!\\s|\\d)\\S[\\s\\S]*$"
                },
              ]}

            >
              <Input placeholder={Cluar.plainDictionary("placeholder-name")} />
            </Form.Item>
            <Form.Item
              {...layout.labelCol}
              label="E-mail"
              name={["contactForm", "email"]}
              rules={[{ required: true, type: "email" }]}
            >
              <Input placeholder={Cluar.plainDictionary("placeholder-email")} />
            </Form.Item>
            <Form.Item
              {...layout.labelCol}
              label={Cluar.plainDictionary("label-telephone")}
              name={["contactForm", "phone"]}
              rules={[
                {
                  required: true,
                  pattern: /^\d+$/,
                  message: Cluar.plainDictionary("rules-error-phone")
                }
              ]}
            >
              <Input
                placeholder={Cluar.plainDictionary("placeholder-telephone")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} justify="space-around">
          <Col xs={24} sm={24} md={18} lg={16} xl={10}>
            <Form.Item
              {...formItemLayoutRequest}
              name={["contactForm", "subject"]}
              label={Cluar.plainDictionary("label-subject")}
              rules={[
                {
                  required: true,
                },
                {
                  type: "string",
                  message: Cluar.plainDictionary("rules-error-subject"),
                  pattern: "^(?!\\s)\\S[\\s\\S]*$"
                },
              ]}
            >
              <Input
                placeholder={Cluar.plainDictionary("placeholder-subject")}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayoutRequest}
              name={["contactForm", "message"]}
              label={Cluar.plainDictionary("label-additional")}
            >
              <TextArea rows={10} />
            </Form.Item>
            <Form.Item
              {...formItemLayoutRequest}
              name={["contactForm", "file"]}
              label={<Dictionary entry="upload-button-text" oneLine />}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra=""
              className="upload"
            >
              <Upload {...propsUpload}>
                <Button icon={<UploadOutlined />}>
                  <Dictionary entry="upload-placeholder-text" oneLine />
                </Button>
              </Upload>
            </Form.Item>
            <Alert
              message={
                <Dictionary entry="upload-message-additional " oneLine />
              }
              type="info"
              showIcon
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={() => { }}
              style={{ marginTop: 24 }}
              onChange={(value) => {
                _service({
                  method: "POST",
                  url: "/recaptcha",
                  data: {
                    value,
                  },
                  success: (response) => {
                    response.json.success
                      ? setIsHuman(true)
                      : setIsHuman(false);
                  },
                  fail: (e) => {
                    setLoading(false);
                    console.log(e)
                    notification["error"]({
                      message: "Ocorreu um erro a carregar os dados",
                      description:
                        "Ocorreu um erro a carregar os dados, por favor tente novamente.",
                    });
                  },
                });
              }}
            />
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <div className="btn-form">
                <Button
                  htmlType="submit"
                  {...{ loading }}
                  disabled={!isHuman}
                >
                  <Dictionary entry="send-button" oneLine />
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </section>
  );
};
