import { Collapse } from "antd";
import './index.less'

export function Faq({ title, content }) {

    const faqData = [
        {
            question: 'Qual é a política de devolução?',
            answer: 'Nossa política de devolução permite...'
        },
        {
            question: 'Como faço para entrar em contato com o suporte?',
            answer: 'Você pode entrar em contato com nosso suporte...'
        },
    ];

    return (
        <section className="relative faq">
            <div className="faq-content">
                <div className="styles_container">
                    <div className="faq-content-heading">
                        <h1 className="faq-content-heading-title">{title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                    <Collapse accordion size="large" bordered={false}>
                        {faqData.map((faqItem, index) => (
                            <Collapse.Panel header={faqItem.question} key={index}>
                                <p>{faqItem.answer}</p>
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </div>
            </div>
        </section>
    );
}