import React, { useState } from 'react';
import { Row, Col } from 'antd';
import Dictionary from '../../Dictionary';

//import './index.less';

export default ({ title, content }) => {
  return (
    <section >
      <div className="container--section">
        <div className="pricing-inner section-inner">
          <div className="hosting-small tiles-wrap">
            <div className="tiles-item">
              <div className="tiles-item-inner has-shadow">
                <div className="pricing-item-content">
                  <div className="pricing-item-header pb-16">
                    <h1><Dictionary entry="planos_f1" oneLine /></h1>
                    <div className="pricing-item-price">
                      <Dictionary entry="precos_servidores_f1" oneLine />
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-0">
                    <a className="button"><Dictionary entry="escolher_plano" oneLine /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="tiles-item">
              <div className="tiles-item-inner has-shadow">
                <div className="pricing-item-content">
                  <div className="pricing-item-header pb-16">
                    <h1><Dictionary entry="planos_f1" oneLine /></h1>
                    <div className="pricing-item-price">
                      <Dictionary entry="precos_servidores_f1" oneLine />
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-0">
                    <a className="button"><Dictionary entry="escolher_plano" oneLine /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="tiles-item">
              <div className="tiles-item-inner has-shadow">
                <div className="pricing-item-content">
                  <div className="pricing-item-header pb-16">
                    <h1><Dictionary entry="planos_f1" oneLine /></h1>
                    <div className="pricing-item-price">
                      <Dictionary entry="precos_servidores_f1" oneLine />
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-0">
                    <a className="button"><Dictionary entry="escolher_plano" oneLine /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="tiles-item">
              <div className="tiles-item-inner has-shadow">
                <div className="pricing-item-content">
                  <div className="pricing-item-header pb-16">
                    <h1><Dictionary entry="planos_f1" oneLine /></h1>
                    <div className="pricing-item-price">
                      <Dictionary entry="precos_servidores_f1" oneLine />
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-0">
                    <a className="button"><Dictionary entry="escolher_plano" oneLine /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

}


