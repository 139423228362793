import React, { useState, useEffect } from 'react';

import { Layout, Menu, Row, Col, Button, ConfigProvider, Space } from 'antd';
import { GlobalOutlined, LinkedinFilled, GithubFilled, FacebookFilled, WhatsAppOutlined, InstagramFilled, MailFilled, BulbOutlined } from '@ant-design/icons';

import Burger from '@animated-burgers/burger-slip';
import '@animated-burgers/burger-slip/dist/styles.css';
import classNames from 'classnames';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";

import Analytics from './common/Analytics';
import Cluar from './common/Cluar';
import Builder from './common/Builder';
import Cookies from './components/Cookies';
import Dictionary from './components/Dictionary';
import NotFound from './pages/NotFound';
import ContactFix from './components/functionality/ContactFix';

import './styles/App.less';
import './styles/container.less'
import { Foo } from './Foo';

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

export default () => {

  let urlLang = null;
  if (urlLang = /^\/([a-z]+)\//ig.exec(window.location.pathname)) {
    Cluar.changeLanguage(urlLang[1]);
  } else {
    const storageLocale = window.localStorage.getItem('locale');
    if (storageLocale == null) {
      Cluar.changeLanguage(Cluar.defaultLanguage().locale);
    } else {
      Cluar.changeLanguage(storageLocale);
    }
  }

  const [burgerMenu, setBurgerMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState('main');
  const [locale, setLocale] = useState(Cluar.currentLanguage().locale);
  const [isMobile, setMobile] = useState(false);

  const handleMenuClick = (selectMenu) => {
    setBurgerMenu(false);
    if (selectMenu) {
      setActiveMenu(selectMenu);
    }
    window.scrollTo(0, 0);
  };

  const menuLanguages = [];
  const menu = [];
  const subMenuKeys = [];
  const routes = [];
  for (const language of Cluar.languages()) {
    if (!Cluar.pages()[language.code]) {
      continue;
    }

    if (language.code !== Cluar.currentLanguage().code) {
      menuLanguages.push({
        key: language.code,
        onClick: () => {
          Cluar.changeLanguage(language.locale);
          window.localStorage.setItem("locale", Cluar.currentLanguage().locale);
          window.location.href = `/${language.locale}/`;

        },
        label: <Space align='center'>
          {language.image ? (
            <img style={{ maxWidth: 24, marginTop: -2 }} src={`/images/language/${language.image}`} />
          ) : null}
          {language.description}
        </Space>

      });

    }

    const buildMenu = (page, level) => {
      if (page.menu && language.code === Cluar.currentLanguage().code) {
        const key = `${page.link}`;
        if (Cluar.pages()[language.code].find((p) => p.menu && p.parent === page.link)) {
          subMenuKeys.push(key);
          return {
            key,
            popupClassName: `header-submenu ${level + 1}`,
            label: page.title,
            children: Cluar.pages()[language.code].filter((p) => p.menu && p.parent === page.link).map((p) => buildMenu(p, level + 1))
          }

        } else {
          if (level == 1) {
            return {
              key,
              label: (
                <Link
                  to={`/${Cluar.currentLanguage().locale}${page.link}`}
                  onClick={() => handleMenuClick(key)}
                  className="sub-menu-item-wrapper"
                >
                  <p className="sub-menu-item-title">{page.title}</p>
                  <p className="sub-menu-item-description">
                    {page.description}
                  </p>
                </Link>
              ),
            };
          }

          return {
            key,
            label: (
              <Link
                to={`/${Cluar.currentLanguage().locale}${page.link}`}
                onClick={() => handleMenuClick(key)}
              >
                {page.title}
              </Link>
            ),
          };
        }
      }
      return null;
    };




    const subroutes = [];

    for (const page of Cluar.pages()[language.code]) {
      if (page.menu && page.parent === "" && language.code === Cluar.currentLanguage().code) {
        menu.push(
          buildMenu(page, 0)
        );
      }
      subroutes.push(
        <Route key={`/${language.locale}/${page.link}/`} path={`/${language.locale}${page.link}`} element={<Builder page={page} />} />
      );
    }
    routes.push(
      <Route key={`/${language.locale}/`} path={`/${language.locale}/`}>
        {subroutes}
      </Route>
    );
  }

  useEffect(() => {
    const checkScreenWidth = () => {
      const screenWidth = window.innerWidth;
      setMobile(screenWidth <= 820);
    };

    checkScreenWidth();

    window.addEventListener('resize', checkScreenWidth);

    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setBurgerMenu(false);
    }
  }, [isMobile]);

  const socialLinks = [
    {
      title: "Facebook",
      url: "https://www.facebook.com/www.veks.net",
      icon: <FacebookFilled />,
    },
    {
      title: "Github",
      url: "https://github.com/veks-net",
      icon: <GithubFilled />,
    },
    {
      title: "Instagram",
      url: "https://www.instagram.com/veksnet/",
      icon: <InstagramFilled />,
    },
    {
      title: "Linkedin",
      url: " https://www.linkedin.com/company/veks-net/about/",
      icon: <LinkedinFilled />,
    },
  ];

  const contactData = [
    {
      title: "WhatsApp",
      icon: <WhatsAppOutlined />,
      link: "https://wa.me/+351912524751",
      text: "WhatsApp"
    },
    {
      title: "Email",
      icon: <MailFilled />,
      link: "mailto:admin@veks.net",
      text: "admin@veks.net"
    },
    {
      title: "Email",
      icon: <MailFilled />,
      link: "mailto:support@veks.net",
      text: "support@veks.net"
    }
  ];

  useEffect(() => {
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#f88668',
          colorInfo: '#f88668',
          fontSize: 16,
          borderRadius: 20,
          fontFamily: 'Montserrat, sans-serif'
        },
        components: {
          Layout: {
            colorBgHeader: '#f3f3f3'
          },
          Menu: {
            colorItemBgHover: 'transparent',
            colorItemTextHover: '#f88668',
          }
        }
      }}
    >
      <Router>
        {Analytics.init() && <Analytics.RouteTracker />}
        <div className="page">
          <Layout>
            <Header className={classNames({ 'header-burger-open': burgerMenu })}>
              <div className='styles_container wrapper'>
                <div className="brand">
                  <a href={`/${Cluar.currentLanguage().locale}/`} onClick={(e) => handleMenuClick('/')
                  }>
                    <img alt="brand" src="/images/logo-final.svg" />
                  </a>
                </div>
                <div className={classNames({ 'menu': true })}>
                  <Menu theme="light" mode="horizontal" defaultSelectedKeys={[activeMenu]} selectedKeys={[activeMenu]} items={menu} />
                </div>
                <div className={classNames({ 'menu': true, 'menu-burger': true, 'menu-burger-open': burgerMenu })}>
                  <Menu theme="light" mode="inline" defaultSelectedKeys={[activeMenu]} selectedKeys={[activeMenu]} items={menu} />
                </div>
                <div className="menu-burger-button">
                  <Burger isOpen={burgerMenu} onClick={() => { setBurgerMenu(!burgerMenu); }} />
                </div>
                <div className='login-button-header'>
                  <a className='login-button' href='https://my.veks.net/'>
                  <Dictionary entry="login-button" oneLine />
                  </a>
                </div>
                <Menu theme="light" className="menu-languages" mode={'horizontal'} defaultSelectedKeys={[activeMenu]} selectedKeys={[activeMenu]} forceSubMenuRender={true}
                  items={[{
                    icon: Cluar.currentLanguage().image ? (<img style={{ maxWidth: 24, marginTop: -2 }} src={`/images/language/${Cluar.currentLanguage().image}`} />) : <GlobalOutlined />,
                    label: Cluar.currentLanguage().code,
                    children: menuLanguages,
                  }]}
                />
              </div>
            </Header>
            <Content>
              <Routes>
                <Route path="/" element={<Navigate to={`/${Cluar.currentLanguage().locale}/`} />} />
                {routes}
                <Route path="/foo/*" element={<Foo />} />
                <Route path="*" component={NotFound} />
              </Routes>
            </Content>
            <Footer>
              <div className="styles_container styles_container_footer">
                <Row align="middle" gutter={[0, 10]} className='ant-layout-footer__wrapper-top'>
                  <Col xs={24} lg={8}>
                    <div className='container-img'>
                      <Link to="/" className="logo-container"><img alt="logo" src="/images/logo-final-dark.svg" /></Link>
                    </div>
                    <Row className="ant-layout-footer__wrapper-bottom">
                      <Col>
                        <div className="netuno-copyright">
                          <p>
                            Copyright © &nbsp;
                            <a
                              href="https://www.sitana.pt"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Sitana
                            </a>
                            &nbsp; {new Date().getFullYear()}
                          </p>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="footer-wrap-terms-privacity">
                          <div className="footer-terms-privacity">
                            <a target="_self" href={`/${Cluar.currentLanguage().locale}/${Cluar.currentLanguage().locale === 'pt' ? 'termos-condicoes' : 'terms-conditions'}`}>
                              <Dictionary entry="terms-and-conditions" oneLine />
                            </a>
                            <span className='dividing-bar' style={{ color: '#fff' }}>|</span>
                            <a target="_self" href={`/${Cluar.currentLanguage().locale}/${Cluar.currentLanguage().locale === 'pt' ? 'politica-privacidade' : 'policy-privacy'}`}>
                              <Dictionary entry="privacy-policy" oneLine />
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="ant-layout-footer__wrapper-bottom">
                      <div className="footer-wrap">
                        <div className="footer-socials">
                          {socialLinks.map((link, index) => {
                            return (
                              <Col>
                                <a key={index} href={link.url} title={link.title} target="_blank" className="share-icons">
                                  {link.icon}
                                </a>
                              </Col>
                            )
                          })}
                        </div>
                      </div>
                    </Row>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 8, offset: 8 }} className="contacts">
                    <div className='footer-wrap'>
                      <div className='contact-mail'>
                        <a href='https://my.veks.net/' target="_blank" className="login-button">
                          <Dictionary entry="client-area" oneLine />
                        </a>
                        {contactData.map((contact, index) => (
                          <Row key={index}>
                            <Col>
                              <a title={contact.title} className="share-icons" href={contact.link} target="_blank">
                                {contact.icon} &nbsp; {contact.text}
                              </a>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Footer>
            <ContactFix />
            <Cookies />
          </Layout>
        </div>
      </Router>
    </ConfigProvider >
  );
}
