import React from 'react';

import Dictionary from '../../Dictionary';

import './index.less'

export default ({ title, content }) => {
    return (
        <>
            <a name="pricingDetails"></a>
            <section className='products-pricingDetails styles_container'>
                <div className='background-productsDetails'></div>
                <div className='products-pricingDetails-title'>
                    <h1>{title}</h1>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th><Dictionary entry="features" /></th>
                            <th>Plano Start</th>
                            <th>Plano Pro</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Cores</td>
                            <td data-label="Plano Start">1 Core</td>
                            <td data-label="Plano Pro">2 Cores</td>
                        </tr>
                        <tr>
                            <td><Dictionary entry="memory-container" /></td>
                            <td data-label="Plano Start">3 GB</td>
                            <td data-label="Plano Pro">6 GB</td>
                        </tr>
                        <tr>
                            <td><Dictionary entry="disco" /></td>
                            <td data-label="Plano Start">25 GB - SSD</td>
                            <td data-label="Plano Pro">50 GB - SSD</td>
                        </tr>
                    </tbody></table>
            </section>
        </>
    )
}