import React, { useState } from 'react';
import { Row, Col } from 'antd';
import Dictionary from '../../Dictionary';
import Cluar from '../../../common/Cluar';
//import config from '../../../config/config-dev.json';

import './index.less';

export default ({ title, content }) => {
    return (
        <section class="pricing has-bg-color">
            <div className="container">
                <div className="tiles-item">
                    <div className="tiles-wrap">
                        <div className="container-xs">
                            <h1 className="mt-0 fw-700">{title}</h1>
                            <p className="mt-0 fw-400">{content}</p>
                        </div>
                    </div>
                    <div className="container--box">
                        <div className="tiles-items">
                            <div className="pricing-item-header illustration-element-06">
                                <div className="box">
                                    <div className="pricing-item-content">
                                        <h2>Plano Base</h2>
                                        <div className='pricing-itens-texts'>
                                            <span className="pricing-plans text-xxs fw-700"></span>
                                        </div>
                                    </div>
                                    <div className="pricing-item-features">
                                        <div className="pricing-item-features-title text-xs">
                                            <span><Dictionary entry="plans-content" oneLine /></span>
                                        </div>
                                        <div className="pricing-item-features-list text-xs">
                                            <ul className="pricing-item-features-list">
                                                <li><Dictionary entry="features-plans" oneLine /></li>
                                                <li><Dictionary entry="features-plans" oneLine /></li>
                                                <li><Dictionary entry="features-plans" oneLine /></li>
                                                <li><Dictionary entry="features-plans" oneLine /></li>
                                                <li><Dictionary entry="features-plans" oneLine /></li>
                                            </ul>
                                        </div>
                                        <a className="button button-primary button-block"><Dictionary entry="button-plans" oneLine /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-item-header illustration-element-06">
                                <div className="box">
                                    <div className="pricing-item-content">
                                        <h2><Dictionary entry="precos-planos" oneLine /></h2>
                                        <span className="pricing-plans text-xxs">Plano Base</span>
                                    </div>
                                    <div className="pricing-item-features">
                                        <div className="pricing-item-features-title text-xs">
                                            <span><Dictionary entry="plans-content" oneLine /></span>
                                        </div>
                                        <div className="pricing-item-features-list text-xs">
                                            <ul className="pricing-item-features-list">
                                                <li><Dictionary entry="features-plans" oneLine /></li>
                                                <li><Dictionary entry="features-plans" oneLine /></li>
                                                <li><Dictionary entry="features-plans" oneLine /></li>
                                                <li><Dictionary entry="features-plans" oneLine /></li>
                                                <li><Dictionary entry="features-plans" oneLine /></li>
                                            </ul>
                                        </div>
                                        <a className="button button-primary button-block"><Dictionary entry="button-plans" oneLine /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}



