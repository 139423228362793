import React, { useState } from 'react';
import { Row, Col, Card } from 'antd';
import Dictionary from '../../Dictionary';
import Cluar from '../../../common/Cluar';
//import config from '../../../config/config';

import './index.less';

export default ({ title, content }) => {

    return (
        <section className="layout--pricingbig">
            <div className="layout--pricingbig-inner">
                <div className="layout--pricing-items">
                    <div className="layout--pricing-title" >
                        <h1>{title}</h1>
                    </div>
                    <div className="layout--pricing-big-table-inner">
                        <div className="layout--pricing-item">
                            <div className="layout--pricing-wrap">
                                <div className="layout--pricing-content">
                                    <h3 className="plan">Planos</h3>
                                    <div className="price">
                                        <span className="euro">€</span>
                                        19
                                        <span className="month">/Mês</span>
                                    </div>
                                    <button>Entrar aqui</button>
                                </div>
                            </div>
                        </div>
                        <div className="layout--pricing-item">
                            <div className="layout--pricing-wrap">
                                <div className="layout--pricing-content">
                                    <h3 className="plan">Planos</h3>
                                    <div className="price">
                                        <span className="euro">€</span>
                                        19
                                        <span className="month">/Mês</span>
                                    </div>
                                    <button>Entrar aqui</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> {/* finish end of item div */}
                <div className="layout--pricing-table">
                    <div className="layout--table-title">
                        <span><Dictionary entry="core-functionalities" oneLine /></span>
                    </div>
                    <div className="layout--table-items">
                        <table>
                            <tr>
                                <td>SEO</td>
                                <td><span className="tick">✔</span></td>
                                <td>✔</td>
                            </tr>
                            <tr>
                                <td>SEO</td>
                                <td>Base</td>
                                <td>Base</td>
                            </tr>
                            <tr>
                                <td>SEO</td>
                                <td>Base</td>
                                <td>Base</td>
                            </tr>
                            <tr>
                                <td>SEO</td>
                                <td>Base</td>
                                <td>Base</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="layout--pricing-table">
                    <div className="layout--table-title">
                        <span><Dictionary entry="table--eCommerce" oneLine /></span>
                    </div>
                    <div className="layout--table-items">
                        <table>
                            <tr>
                                <td>SEO</td>
                                <td>Base</td>
                                <td>Base</td>
                            </tr>
                            <tr>
                                <td>SEO</td>
                                <td>Base</td>
                                <td>Base</td>
                            </tr>
                            <tr>
                                <td>SEO</td>
                                <td>Base</td>
                                <td>Base</td>
                            </tr>
                            <tr>
                                <td>SEO</td>
                                <td>Base</td>
                                <td>Base</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <Card className="custom-pricing">
                    <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                            <h5>Necessita de outras funcionalidades adicionais? <br /> Fale connosco para um plano customizável.</h5>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                            <a className="primary-button">
                                <button className="btn-customize" >Pedir Orçamento</button>
                            </a>
                        </Col>
                    </Row>
                </Card>
            </div>
        </section>
    );

}



