import React, { useEffect } from 'react';

import sal from 'sal.js';

import Banner from '../components/Banner';
import Content from '../components/Content';
import Listing from '../components/Listing';
import ContactForm from '../components/functionality/ContactForm';
import WebsitePlans from '../components/functionality/WebsitePlans';
import CardPlans from '../components/functionality/CardPlans';
import PlansPricing from '../components/functionality/PlansPricing';
import WebsitePlansBig from '../components/functionality/WebsitePlansBig';
import Map from '../components/functionality/Map';
import ProductsPageTemplate from '../components/functionality/ProductsPageTemplate';
import config from '../config/config.json';
import TablePricing from '../components/functionality/TablePricingDeveloper';
import ContactFix from '../components/functionality/ContactFix';
import WebsiteForm from '../components/functionality/WebsiteForm';
import ContainerPlans from '../components/functionality/ContainerPlans';
import TablePricingDeveloper from '../components/functionality/TablePricingDeveloper';
import TablePricingContainer from '../components/functionality/TablePricingContainer';
import { Faq } from '../components/functionality/Faq';

export default ({ page }) => {

    useEffect(() => {
        sal();
        document.getElementsByTagName('meta')["keywords"].content = page.keywords;
        document.getElementsByTagName('meta')["description"].content = page.description;
        document.title = page.title + ' | ' + config.website.name;
    }, []);

    const components = [];
    for (const item of page.structure) {
        if (item.section === 'banner') {
            components.push(<Banner key={components.length} {...item} />);
        } else if (item.section === 'content') {
            components.push(<Content key={components.length} {...item} />);
        } else if (item.section === 'listing') {
            components.push(<Listing key={components.length} {...item} />);
        } else if (item.section === 'functionality') {
            if (item.type === 'map') {
                components.push(<Map key={components.length} {...item} />);
            } else if (item.type === 'contact-form') {
                components.push(<ContactForm key={components.length} {...item} />);
            } else if (item.type === 'website-plans') {
                components.push(<WebsitePlans key={components.length} {...item} />)
            } else if (item.type === 'pricing-plans') {
                components.push(<PlansPricing key={components.length} {...item} />)
            } else if (item.type === 'website-plans-big') {
                components.push(<WebsitePlansBig key={components.length} {...item} />)
            } else if (item.type === 'card-plans') {
                components.push(<CardPlans key={components.length} {...item} />)
            } else if (item.type === 'products-page-template') {
                components.push(<ProductsPageTemplate  {...item} />)
            } else if (item.type === 'table-pricing-developer') {
                components.push(<TablePricingDeveloper key={components.length} {...item} />)
            } else if (item.type === 'table-pricing-container') {
                components.push(<TablePricingContainer key={components.length} {...item} />)
            } else if (item.type === 'contact-fix') {
                components.push(<ContactFix {...item} />)
            } else if (item.type === 'website-form') {
                components.push(<WebsiteForm {...item} />)
            } else if (item.type === 'container-plans') {
                components.push(<ContainerPlans {...item} />)
            } else if (item.type === 'faq-veks') {
                components.push(<Faq {...item} />)
            }
        }
    }
    return <main>
        {components}
    </main>;
}
