import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Input, Button, Space } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import Actions from '../Actions';
import Cluar from '../../common/Cluar';
import Dictionary from '../../components/Dictionary';

import './index.less';
import { Newsletter } from '../functionality/Newsletter';

const { Search } = Input;

export default ({ section, type, image, image_title, image_alt, title, content, position, actions }) => {
  let backgroundPositionX = position.x !== "" ? position.x : "102%";
  let backgroundPositionY = position.y !== "" ? position.y : "50%";


  if (type === 'home-highlight') {
    return (
      <section className="home__base styles_section styles_container">
        <div className={`styles_container ${type}`} style={{
          backgroundImage: `url(/images/${section}/${image})`,
          backgroundPositionX,
          backgroundPositionY,
        }}>
        </div>
        <Row wrap={false}>
          <div className="home__figure--mobile">
            <Col xs={24} sm={24} md={24} lg={24}>
              {Cluar.currentLanguage().locale === 'pt' ?
                <img
                  data-sal="fade" data-sal-duration="2000" data-sal-easing="ease-out-cubic"
                  alt={image} src="/images/workwithus-bg-final-PT.png" />
                :
                <img
                  data-sal="fade" data-sal-duration="2000" data-sal-easing="ease-out-cubic"
                  alt={image} src="/images/workwithus-bg-final-EN.png" />
              }
            </Col>
          </div>
          <Col xs={24} sm={24} md={24} lg={24} >
            <div className="home__content">
              <h1 data-sal="slide-down" data-sal-duration="2000" data-sal-easing="ease-out-cubic" className="text-title fw-700">{title}</h1>
              <div data-sal="fade" data-sal-duration="2000" data-sal-easing="ease-out-cubic" dangerouslySetInnerHTML={{ __html: content }}></div>
              {/*<Actions  {... { section, type, actions }} />*/}
            </div>
          </Col>
        </Row>
        {/*<div className='background--orange'></div>*/}
      </section >
    );
  } else if (type === 'banner-container') {
    return (
      <section className={`${type}-highlight`}>
        <Row className={`${type}-highlight-content styles_container`}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} >
            <div className={`${type}-highlight-content-text`}>
              <h1 data-sal="slide-down" data-sal-duration="2000" data-sal-easing="ease-out-cubic" className="text-title fw-700">{title}</h1>
              <div data-sal="fade" data-sal-duration="2000" data-sal-easing="ease-out-cubic" dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
            <Row align='middle' className={`${type}-highlight-content-buttons`}>
              <Newsletter />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className={`${type}-highlight-content-image`}>
              <img src={`/images/${section}/${image}`} />
            </div>
          </Col>
        </Row>
      </section>
    )

  } else if (type === 'banner-text-only') {
    return (
      <section className="banner-text-only styles_section illustration-section-02">
        <div className='styles_container'>
          <div className='heading-text'>
            <h1 className='fw-700'>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </div>
      </section>

    );
  } else if (type === 'section-dev') {
    return (
      <section className='banner-hero'>
        <Row align="middle" className='banner-hero__row styles_container' wrap={false}>
          <Col xs={24} sm={24} md={12} lg={12} className='banner-hero__row__info'>
            <span>{title}</span>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className='banner-hero__row-image'>
              <img key={`/images/${section}/${image}`} src={`/images/${section}/${image}`} />
            </div>
          </Col>
        </Row>
        {/*<div className='background-fake'></div> */}
      </section>

    );
  }
  else {
    return (
      <section className="banner container">
        <div className={`banner__${type}`} style={{
          backgroundImage: `url(/images/${section}/${image})`,
          backgroundPositionX,
          backgroundPositionY
        }}>
          <Row gutter={[40, 24]}>
            <Col span={12}>
              <div className="home__content">
                <h1>{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
                <Actions  {... { section, type, actions }} />
              </div>
            </Col>
          </Row>
          <div className="banner__darken-bg"></div>
        </div>
      </section >
    );
  }
}
