import { ArrowRightOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import Dictionary from '../../../Dictionary';
import Cluar from '../../../../common/Cluar';
import './index.less'

const cardData = [
    {
        title: 'C1',
        money: '20€',
        moneyTitle: {
            en: '/ month',
            pt: '/ mês',
        },
        cpuPlan: '2 Cores - CPU',
        ramPlan: '6 GB - RAM',
        ssdPlan: {
            en: '50 GB - SSD',
            pt: '50 GB - SSD',
        },
        hhdPlan: {
            en: 'or 200 GB - HDD',
            pt: 'ou 200 GB - HDD'
        }
    },
    {
        title: 'C2S',
        money: '40€',
        moneyTitle: {
            en: '/ month',
            pt: '/ mês',
        },
        cpuPlan: '4 Cores - CPU',
        ramPlan: '14 GB - RAM',
        ssdPlan: {
            en: '100 GB - SSD',
            pt: '100 GB - SSD',
        },
        hhdPlan: {
            en: 'or 400 GB - HDD',
            pt: 'ou 400 GB - HDD'
        }
    },
    {
        title: 'C3L',
        money: '80€',
        moneyTitle: {
            en: '/ month',
            pt: '/ mês',
        },
        cpuPlan: '8 Cores - CPU',
        ramPlan: '30 GB - RAM',
        ssdPlan: {
            en: '200 GB - SSD',
            pt: '200 GB - SSD',
        },
        hhdPlan: {
            en: 'or 800 GB - HDD',
            pt: 'ou 800 GB - HDD'
        }
    },
];

export function CardsContainer() {

    const languages = Cluar.currentLanguage().locale === 'pt' ? 'pt' : 'en'

    return (
        <div className='cards-container'>
            <div className='heading text_center'>
                <Dictionary entry="session-title-container" oneLine />
            </div>
            <div className="switch_plans_container_wrapper_grid">
                {cardData.map((card, index) => (
                    <div key={index} className="switch_plans_container_wrapper_grid_card">
                        <div className="switch_plans_container_wrapper_grid_card_heading">
                            <h1 className="title">{card.title}</h1>
                            <p className='title-money'>{card.money}
                                <span className='complementary-money' >
                                    {card.moneyTitle[languages]}
                                </span>
                            </p>
                        </div>
                        <hr />
                        <ul>
                            <li>{card.cpuPlan}</li>
                            <li>{card.ramPlan}</li>
                            <li>{card.ssdPlan[languages]}</li>
                            <li>{card.hhdPlan[languages]}</li>
                        </ul>
                    </div>
                ))}
            </div>
            <div className='more-info'>
                <a href={`/${Cluar.currentLanguage().locale}/vps/${Cluar.currentLanguage().locale === 'pt' ? 'containers' : 'containers'}`}>
                    <span className='more-info-button'>
                        Saiba mais
                        <ArrowRightOutlined style={{
                            fontSize: "16px"
                        }} />
                    </span>
                </a>
            </div>
        </div>
    );
}
