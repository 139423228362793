import React from 'react';
import { Col, Button, Row } from 'antd';
import Dictionary from '../../Dictionary';
import ReactSpeedometer from "react-d3-speedometer"
import Cluar from '../../../common/Cluar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import './index.less';
import Checkbox from 'antd/es/checkbox/Checkbox';


export default ({ section, type, image, image_title, image_alt, title, content, link, index, effect, value }) => {
  let layout = null;

  const onClick = () => {
    window.open('https://my.veks.net/login', '_blank');
  }

  if (type === 'listing_tabList') {
    layout = (
      <div className='tabList'>
        <div data-sal="slide-up" data-sal-duration="1200" data-sal-delay="50" data-sal-easing="ease-out-bounce" className="tabList-text">
          <h1 className="text-xxs fw-700">{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
        <div className="tabList-image">
          <div className='styles-img'>
            <img
              src={`/images/${section}/${image}`} alt={image_alt} />
          </div>
        </div>
      </div>
    );
  } else if (type === 'pricing_plans') {
    layout = (
      <ul className='cards_base' index={index}>
        <div className='cards_pricing illustration-element-07'>
          <div className='cards_styles'>
            <div className='cards_headline'>
              <h1>{title}</h1>
            </div>
          </div>
          <div className='cards_bulletList'>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
          {/*
          <Button type="primary">
            <a href={`/${Cluar.currentLanguage().locale}${link}`} className="btn btn-primary"><Dictionary entry="Choose-plan" oneLine /></a>
          </Button>
          */}
        </div>
      </ul>
    );
  } else if (type === 'vps_services') {
    layout = (
      <ul className='cards_base'>
        <div className='cards_pricing'>
          <div className='cards_styles'>
            <div className='cards_headline'>
              <h1>{title}</h1>
              <div index={index} className='cards_speed'>
                {index == 0 && effect ?
                  <ReactSpeedometer
                    width={200}
                    value={200}
                    needleTransitionDuration={4000}
                    currentValueText=""
                    needleTransition="easeElastic"
                    needleHeightRatio={0.7}
                    customSegmentStops={[0, 250, 750, 1000]}
                    segmentColors={['#f88668', '#1d2b4b', '#1c6bbc']}
                    customSegmentLabels={[
                      {
                        text: "Good",
                        position: "OUTSIDE",
                        fontSize: "1rem",
                        color: "#f09089",
                      },
                      {
                        text: "Great",
                        position: "OUTSIDE",
                        fontSize: "1rem",
                        color: "#1d2b4b",
                      },
                      {
                        text: "Awesome ",
                        position: "OUTSIDE",
                        color: "#1c6bbc",
                        fontSize: "1rem",
                      }
                    ]}
                  />
                  : index == 1 && effect ?
                    <ReactSpeedometer
                      width={200}
                      value={600}
                      needleTransitionDuration={4000}
                      currentValueText=""
                      needleTransition="easeElastic"
                      needleHeightRatio={0.7}
                      customSegmentStops={[0, 250, 750, 1000]}
                      segmentColors={['#f88668', '#1d2b4b', '#1c6bbc']}
                      customSegmentLabels={[
                        {
                          text: "Good",
                          position: "OUTSIDE",
                          fontSize: "1rem",
                          color: "#f09089",
                        },
                        {
                          text: "Great",
                          position: "OUTSIDE",
                          fontSize: "1rem",
                          color: "#1d2b4b",
                        },
                        {
                          text: "Awesome ",
                          position: "OUTSIDE",
                          color: "#1c6bbc",
                          fontSize: "1rem",
                        }
                      ]}
                    />
                    : <ReactSpeedometer
                      width={200}
                      value={value}
                      currentValueText=""
                      needleTransitionDuration={4000}
                      needleTransition="easeElastic"
                      needleHeightRatio={0.7}
                      customSegmentStops={[0, 250, 750, 1000]}
                      segmentColors={['#f88668', '#1d2b4b', '#1c6bbc']}
                      customSegmentLabels={[
                        {
                          text: "Good",
                          position: "OUTSIDE",
                          fontSize: "1rem",
                          color: "#f09089",
                        },
                        {
                          text: "Great",
                          position: "OUTSIDE",
                          fontSize: "1rem",
                          color: "#1d2b4b",
                        },
                        {
                          text: "Awesome ",
                          position: "OUTSIDE",
                          color: "#1c6bbc",
                          fontSize: "1rem",
                        }
                      ]}
                    />
                }
              </div>
              {/*  <Button>
              <a href={`/${Cluar.currentLanguage().locale}${link}`}><Dictionary entry="Choose-plan" oneLine /></a>
              </Button> */}
            </div>
            <div className='card_bulletItems'>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </div>
        </div>
      </ul>
    );
  } else if (type === 'contracting_services') {
    layout = (
  <ul className='cards_base' onClick={onClick} style={{ cursor: "pointer"}}>
     <div className='cards_pricing'>
       <div className='cards_styles'>
         <div className='cards_headline'>
           <h1>{title}</h1>
           <div index={index} className='cards_speed'>
             {index == 0 && effect ?
               <ReactSpeedometer
                 width={200}
                 value={200}
                 needleTransitionDuration={4000}
                 currentValueText=""
                 needleTransition="easeElastic"
                 needleHeightRatio={0.7}
                 customSegmentStops={[0, 250, 750, 1000]}
                 segmentColors={['#f88668', '#1d2b4b', '#1c6bbc']}
                 customSegmentLabels={[
                   {
                     text: "Good",
                     position: "OUTSIDE",
                     fontSize: "1rem",
                     color: "#f09089",
                   },
                   {
                     text: "Great",
                     position: "OUTSIDE",
                     fontSize: "1rem",
                     color: "#1d2b4b",
                   },
                   {
                     text: "Awesome ",
                     position: "OUTSIDE",
                     color: "#1c6bbc",
                     fontSize: "1rem",
                   }
                 ]}
               />
               : index == 1 && effect ?
                 <ReactSpeedometer
                   width={200}
                   value={600}
                   needleTransitionDuration={4000}
                   currentValueText=""
                   needleTransition="easeElastic"
                   needleHeightRatio={0.7}
                   customSegmentStops={[0, 250, 750, 1000]}
                   segmentColors={['#f88668', '#1d2b4b', '#1c6bbc']}
                   customSegmentLabels={[
                     {
                       text: "Good",
                       position: "OUTSIDE",
                       fontSize: "1rem",
                       color: "#f09089",
                     },
                     {
                       text: "Great",
                       position: "OUTSIDE",
                       fontSize: "1rem",
                       color: "#1d2b4b",
                     },
                     {
                       text: "Awesome ",
                       position: "OUTSIDE",
                       color: "#1c6bbc",
                       fontSize: "1rem",
                     }
                   ]}
                 />
                 : <ReactSpeedometer
                   width={200}
                   value={value}
                   currentValueText=""
                   needleTransitionDuration={4000}
                   needleTransition="easeElastic"
                   needleHeightRatio={0.7}
                   customSegmentStops={[0, 250, 750, 1000]}
                   segmentColors={['#f88668', '#1d2b4b', '#1c6bbc']}
                   customSegmentLabels={[
                     {
                       text: "Good",
                       position: "OUTSIDE",
                       fontSize: "1rem",
                       color: "#f09089",
                     },
                     {
                       text: "Great",
                       position: "OUTSIDE",
                       fontSize: "1rem",
                       color: "#1d2b4b",
                     },
                     {
                       text: "Awesome ",
                       position: "OUTSIDE",
                       color: "#1c6bbc",
                       fontSize: "1rem",
                     }
                   ]}
                 />
             }
           </div>
           {/*  <Button>
           <a href={`/${Cluar.currentLanguage().locale}${link}`}><Dictionary entry="Choose-plan" oneLine /></a>
           </Button> */}
         </div>
         <div className='card_bulletItems'>
           <div dangerouslySetInnerHTML={{ __html: content }}></div>
         </div>
       </div>
     </div>
   </ul>
    );
  } else if (type === 'section_bullet') {
    layout = (
      <Row className='tabList'>
        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={10} className="tabList-text">
          <h1 className="text-xxs fw-700">{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={14} xxl={12} className="tabList-image">
          <img src={`/images/${section}/${image}`} alt={image_alt} />
        </Col>
      </Row>
    );
  } else if (type === 'list_of_technologies') {
    layout = (
      <>
        <Col>
          <a className={`${type}-items`} alt={title}>
            <Col className={`${type}-items-image`} >
              <img className="language-image" src={`/images/${section}/${image}`} alt={image_alt} title={image_title} />
            </Col>
            <Col className={`${type}-items-text`} >
              <h1 className='language-title fw-700'>{title}</h1>
            </Col>
          </a>
        </Col>
      </>
    );
  } else if (type === 'section_codespace') {
    layout = (
      <>
        <Row className='tabList d-flex space-beetwen'>
          <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="tabList-text d-flex flex-collumn text-left">
            <h1 className="text-xxs fw-700">{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14} className="tabList-image">
            <img src={`/images/${section}/${image}`} alt={image_alt} />
          </Col>
        </Row>
      </>
    );
  } else if (type === 'section-dev') {
    layout = (
      <>
        <Col className='short-plans_row__items'>
          <div className='short-plans_row__items_item'>
            {index === 1 && <div className='effect-top' />}
            <span className='short-plans_row__items_item-title'>{title}</span>
            <div dangerouslySetInnerHTML={{ __html: content }} defaultValue={{ __html: Cluar.dictionary }} />
          </div>
          <div className='short-plans_row__items_plans'>
            <ul className='short-plans_row__items_plans_plan'>
              <li dangerouslySetInnerHTML={{ __html: Cluar.dictionary(index === 0 ? 'short-plans-itens-start' : 'short-plans-itens-pro') }} />
            </ul>
          </div>
        </Col>
      </>
    );
  } else if (type === 'short-pricing') {
    layout = (
      <>
        <Col xs={24} sm={12} md={8} lg={12} xl={7} xxl={7} className='ProductsShortPricing_row__items'>
          <div className='ProductsShortPricing_row__items_item'>
            <h1 className='ProductsShortPricing_row__items_item-title'>{title}</h1>
          </div>
          <div className='ProductsShortPricing_row__items_plans '>
            <div className='ProductsShortPricing_row__items_plans_plan'>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </div>
        </Col>
      </>
    );
  } else if (type === 'short-pricing-container') {
    layout = (
      <>
        <Col xs={24} sm={12} md={8} lg={12} xl={7} xxl={7} className='ProductsShortPricing_row__items'>
          <div className='ProductsShortPricing_row__items_item'>
            <h1 className='ProductsShortPricing_row__items_item-title'>{title}</h1>
          </div>
          <div className='ProductsShortPricing_row__items_plans '>
            <div className='ProductsShortPricing_row__items_plans_plan'>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </div>
        </Col>
      </>
    );
  } else {
    layout = (
      <li className="listing_item" style={{ backgroundImage: `url(/images/${section}/${image})` }}>
        <div className={`item__${type}`}>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </li>
    );
  }
  return (
    layout
  ); 
}
