import React from 'react';

import Dictionary from '../../Dictionary';

export default ({ title, content }) => {
    return (
        <>
            <a name="pricingDetails"></a>
            <section className='products-pricingDetails styles_container'>
                <div className='background-productsDetails'></div>
                <div className='products-pricingDetails-title'>
                    <h1>{title}</h1>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th><Dictionary entry="features"/></th>
                            <th>C1</th>
                            <th>C2S</th>
                            <th>C3L</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Cores</td>
                            <td data-label="C1">2 Cores</td>
                            <td data-label="C2S">4 Cores</td>
                            <td data-label="C3L">8 Cores</td>
                        </tr>
                        <tr>
                            <td><Dictionary entry="memory-container"/></td>
                            <td data-label="C1">6 GB</td>
                            <td data-label="C2S">14 GB</td>
                            <td data-label="C3L">30 GB</td>
                        </tr>
                        <tr>
                            <td><Dictionary entry="disco" /></td>
                            <td data-label="C1"><Dictionary entry="disco-ssd-50gb"/></td>
                            <td data-label="CS"><Dictionary entry="disco-ssd-100gb"/></td>
                            <td data-label="C3L"><Dictionary entry="disco-ssd-200gb"/></td>
                        </tr>
                    </tbody></table>
            </section>
        </>
    )
}